import React from 'react'

function ShowAnswerComponent({ inputTextData, taskInfo }) {
    return (
        <div className="col-md-8 mt-5 offset-md-2 grey__box">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <h2 className="pl-1">Your Answer</h2>
                    </div>
                    <p>{inputTextData}</p>
                </div>
            </div>
            <hr />
            <div className="row mt-5">
                <div className="col-12">
                    <h2>Correct Answer</h2>
                    <p>{taskInfo?.answer}</p>
                </div>
            </div>
        </div>
    )
}

export default ShowAnswerComponent