import * as React from 'react'
import ShowAnswerComponent from 'Components/ShowAnswerComponent'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
import TextEditorWithCounter from 'Components/TextEditorWithCounter'

function WriteAboutThePhoto(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [inputTextData, setInputTextData] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const API_URL = process.env.REACT_APP_DOCUMENT_URL_DOCUMENT_URL

  const handleInput = (e) => {
    const { value } = e.target
    setInputTextData(value)
  }

  const reset = () => {
    setInputTextData("")
    setShowAnswer(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setTimeout(() => {
      setShowAnswer(true);
    }, 100)
  }
  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  const customProps = {
    taskInfo,
    handleInput,
    inputTextData
  }
  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Write one or more sentences that describe the photo</h1>
      </div>

      <div className="container">
        <div className="col-12 col-md-6 mx-auto">
          <div className="row mt-5 pl-3">
            <img className="question__img" src={`${API_URL}/images/${taskInfo?.image}`} />
          </div>
        </div>
        {
          !showAnswer
            ? <TextEditorWithCounter {...customProps} />
            : <ShowAnswerComponent {...customProps} />
        }

        {
          <SubmitRetryButtons retry={reset} submit={submit} />
        }
      </div>
    </div>
  )
}

export default WriteAboutThePhoto