import React from 'react'

function Error() {
    return (
        <>
            <div className="row p-5">
                <div className="col-md-12 text-center">
                    <h1>oopss.. This task is not available for you.</h1>
                </div>
            </div>
        </>
    )
}

export default Error