import React from 'react'

const MicophoneButton = ({ isSupportSpeechAPI, startListening, timer }) => (
    <div className="row mt-4">
        <div className="col-md-6 offset-md-3">
            <div className="speak__box d-flex justify-content-between align-items-center flex-column py-4">

                {
                    !isSupportSpeechAPI
                        ? <p className="no-browser-support text-danger error-label">Sorry, Your Browser Doesn't Support the Web Speech API. Try Opening this in Google Chrome</p>
                        : <>
                            <div className="microphone" onClick={startListening}>
                                <img src={`/assets/website/img/microphone.png`} id="startBtn" />
                            </div>
                            <div className="speak__timer">
                                <h2 style={{ fontSize: '2.6rem' }} id="speak_timer">{timer}</h2>
                            </div>
                        </>
                }
            </div>
        </div>
    </div>
)

export default MicophoneButton