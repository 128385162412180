import * as React from 'react'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'





function ReadAndSelect(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [selectedWords, setSelectedWords] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)

  const handleWordClick = (word) => () => {
    setSelectedWords((prevSelectedWords) => {
      if (prevSelectedWords.includes(word)) {
        return prevSelectedWords.filter((w) => w !== word);
      } else {
        return [...prevSelectedWords, word];
      }
    })
  }

  const getClassNameBySelectedWords = (object) => {
    const { status, word } = object
    const isSelected = selectedWords.includes(word)

    if (isSelected) {
      return status === "True" ? "bc-green" : "bc-red"
    } else {
      return status === "True" ? "bo-green" : "bo-red"
    }
  }


  const reset = () => {
    setShowAnswer(false)
    setSelectedWords([])
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }

  React.useMemo(() => {
    const task = userTasks[openedTaskKey]
    setTaskInfo(task)
    setQuestions(JSON.parse(task?.question_array))
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])
  return (
    <div className="row mt-5">
      <div className="col-md-12 text-center">
        <h1>Select the real English Words</h1>
      </div>
      <div className="container">
        <div className="col-md-12 mt-5">
          {
            !showAnswer
              ? <div className="row pl-5 pr-5" style={{ paddingLeft: '48px', paddingRight: '48px' }}>
                {questions.map((task) => (
                  <div
                    className={`box mr-3 mb-5 w-auto ${selectedWords.includes(task.word) && 'clicked'}`}
                    key={task.word}
                    onClick={handleWordClick(task.word)}
                    style={{ marginLeft: '16px' }}
                  >
                    <div className="label_text">
                      <span className="mb-0">{task.word}</span>
                    </div>
                  </div>
                ))}
              </div>
              : <div className="row pl-5 pr-5 " style={{ paddingLeft: '48px', paddingRight: '48px' }}>
                {
                  questions.map((task, index) => (
                    <div
                      className={`box mr-3 mb-5 w-auto ${getClassNameBySelectedWords(task)}`}
                      key={task.word}
                      style={{ marginLeft: '16px' }}
                    >
                      {task?.word}
                    </div>
                  ))}
              </div>
          }
          <SubmitRetryButtons retry={reset} submit={submit} />
        </div>
      </div>
    </div>
  )
}

export default ReadAndSelect