import * as React from 'react'
import { Footer, Header } from 'Components'
import { LoginComponent } from 'Components/Modals'

const Home = (props) => {

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1><span style={{ color: '#da251c' }}>Prowess&#39;s</span> Practice Platform</h1>

              <h2 style={{ fontSize: '24px', color: '#0f394c' }}>The Best Way To Boost Your Score!</h2>
              <h2>Improve your Duolingo English Test score with our unlimited practice platform. Start for FREE and begin enhancing your score today.</h2>

              <div>
                {/* <a href="student.home" className="btn-get-started scrollto">Get Started</a> */}
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#LoginModal"
                  className="btn-get-started scrollto"
                >Get Started</a>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img src={`/assets/website/img/hero-img.png`} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <LoginComponent {...props} />
    </>
  )
}

export default Home