import React from 'react'

function TextEditorWithCounter({ handleInput, inputTextData }) {
    return (
        <div className="row mt-4">
            <div className="col-md-8 offset-md-2">
                <textarea
                    onChange={handleInput}
                    className="form-control"
                    rows="6"
                    placeholder="Type your answer here.."
                    value={inputTextData}
                />

                <p className="mt-3" style={{ fontSize: '14px' }}> Word Count:
                    <span id="wordCountShow">{inputTextData ? inputTextData?.trim()?.split(" ")?.length : "0"}</span>
                </p>
            </div>
        </div>
    )
}
export default TextEditorWithCounter