import * as React from 'react'

function useCountDown(initialSeconds) {
    const [activeSeconds, setActiveSeconds] = React.useState(initialSeconds)
    const [isActive, setIsActive] = React.useState(false)
    const [countDownFinished, setCountDownFinished] = React.useState(false)

    React.useEffect(() => {
        setActiveSeconds(initialSeconds) // Initialize activeSeconds
    }, [initialSeconds])

    React.useEffect(() => {
        let interval = null
        if (isActive && activeSeconds > 0) {
            interval = setInterval(() => {
                setActiveSeconds((prevSeconds) => prevSeconds - 1) // Update activeSeconds
            }, 1000)
        } else if (activeSeconds === 0) {
            clearInterval(interval)
            if (isActive) setCountDownFinished(true)
            setIsActive(false)
        }
        return () => {
            clearInterval(interval)
        }
    }, [isActive, activeSeconds])

    const startCountDown = () => {
        if (initialSeconds != null) {
            setIsActive(true)
            setCountDownFinished(false)
            setActiveSeconds(initialSeconds) // Reset activeSeconds
        }
    }

    const stopCountDown = () => {
        setIsActive(false)
    }

    const resetCountDown = () => {
        setIsActive(false)
        setActiveSeconds(initialSeconds) // Reset activeSeconds
    }

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60)
        const remainingSeconds = timeInSeconds % 60
        return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`
    }

    return {
        countDownTime: isActive && activeSeconds !== null ? formatTime(activeSeconds) : '00:00',
        startCountDown,
        stopCountDown,
        resetCountDown,
        countDownFinished,
    }
}

export default useCountDown
