import React from 'react'
import { useTimer } from 'Hooks'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
import RemainingListining from 'Components/RemainingListining'
import AudioPlayer from 'Components/AudioPlayer'
import MicophoneButton from 'Components/MicophoneButton'
import ShowAnswerComponent from 'Components/ShowAnswerComponent'
let recognition = null

function ListenThenSpeak(props) {
  const [isSupportSpeechAPI, setIsSupportSpeechAPI] = React.useState(true)
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [countPlay, setCountPlay] = React.useState(3)
  const [playingAudio, setPlayingAudio] = React.useState(false)
  const [speakText, setSpeakTest] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const { timer, startTimer, resetTimer, stopTimer } = useTimer()
  const API_URL = process.env.REACT_APP_DOCUMENT_URL_DOCUMENT_URL
  const audioRef = React.createRef()

  const playAudio = () => {
    if (countPlay <= 0) return
    audioRef.current.play()
    setCountPlay(countPlay - 1)
    setPlayingAudio(true)
  }

  const stopAudio = () => {
    audioRef.current.pause()
    audioRef.current.currentTime = 0
    setPlayingAudio(false)
  }

  const startListening = () => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      setIsSupportSpeechAPI(null)
      return
    }
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    recognition.continuous = true
    recognition.interimResults = true

    let newTranscript = ""
    recognition.onresult = event => {
      newTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('')
      setSpeakTest(newTranscript)
    }

    recognition.onend = () => {
      setSpeakTest(newTranscript)
    }
    recognition.start()
    startTimer()
  }

  const reset = () => {
    setSpeakTest("")
    resetTimer()
    setShowAnswer(false)
    setCountPlay(3)
    setPlayingAudio(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
    stopTimer()
  }

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])


  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Listen to the question and speak your answer</h1>
      </div>

      <div className="container">
        {
          !showAnswer
            ? <>
              <audio id="myAudio" ref={audioRef} onEnded={stopAudio}>
                <source src={`${API_URL}/audio/${taskInfo?.audio}`} type="audio/mpeg" />
              </audio>
              <AudioPlayer playingAudio={playingAudio} playAudio={playAudio} stopAudio={stopAudio} />
              <RemainingListining countPlay={countPlay} />
              <MicophoneButton isSupportSpeechAPI={isSupportSpeechAPI} startListening={startListening} timer={timer} />
            </>
            : <ShowAnswerComponent speakText={speakText} taskInfo={taskInfo} />
        }

        {
          isSupportSpeechAPI && <SubmitRetryButtons retry={reset} submit={submit} />
        }
      </div>
    </div>
  )
}

export default ListenThenSpeak



