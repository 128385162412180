import axios from "axios"
const UNAUTHENTICATE_STATUS_CODES = [401, 402, 403]
const ERROR_STATUS_CODES = [400, 404]




export default async function AxiosInstance({ headers = false, ...rest }) {

    let response = null
    const controller = new AbortController()
    try {
        const baseURL = process.env.REACT_APP_API_URL
        console.log('process.env:', process.env)
        const { signal } = controller
        const config = { baseURL, signal, ...rest }

        const axiosResponse = await Instance({ ...config })
        response = axiosResponse

    } catch (error) {
        if (error.code === 'ERR_NETWORK') {
            response = { data: { status: false, data: null } }
        } else {
            if (error.code !== 'ERR_NETWORK') {
                const { status } = error?.response

                if (ERROR_STATUS_CODES.includes(status)) response = { data: error?.response?.data }

                // if (UNAUTHENTICATE_STATUS_CODES.includes(status)) {
                //     await clearCookieAndSession(removeLoginHistory)
                // }
            }
        }

    }
    controller.abort()
    return response
}

async function Instance({ baseURL, ...rest }) {
    const Instance = axios.create({
        'baseURL': baseURL
    })
    return Instance({ ...rest })
}