import * as React from 'react'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'

function ReadAndComplete(props) {
  const { userTasks, countDownFinished, openedTaskKey, resetCountDownTimer } = props
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [inputValues, setInputValues] = React.useState({})
  const [showAnswer, setShowAnswer] = React.useState(false)

  const reset = () => {
    setShowAnswer(false)
    setInputValues({})
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  React.useEffect(() => {
    const task = userTasks[openedTaskKey]
    setTaskInfo(task)
    reset()
  }, [openedTaskKey, userTasks])

  const handleInputChange = (answerWord, alphabetIndex, value) => {
    if (value.length <= 1) {
      setInputValues((prev) => {
        const updatedValues = { ...prev }

        if (!updatedValues[answerWord]) {
          updatedValues[answerWord] = new Array(answerWord.length).fill('')
        }

        updatedValues[answerWord][alphabetIndex] = value

        // Move cursor to the next input automatically
        if (value.length === 1 && alphabetIndex < answerWord.length - 1) {
          const nextInputRef = inputRefs[answerWord][alphabetIndex + 1]
          if (nextInputRef) {
            nextInputRef.current.focus()
          }
        }

        return updatedValues
      })
    }
  }
  const inputRefs = {}


  const renderQuestionWithInputs = () => {
    if (!taskInfo) return null

    const words = taskInfo.question.split(' ')
    const answerArray = JSON.parse(taskInfo.answer)

    let blankIndex = 0

    const handleBackspace = (answerWord, alphabetIndex) => {
      if (inputValues[answerWord] && inputValues[answerWord][alphabetIndex]) {
        const updatedValues = { ...inputValues }
        const newValue = updatedValues[answerWord][alphabetIndex].slice(0, -1)
        updatedValues[answerWord][alphabetIndex] = newValue
        setInputValues(updatedValues)
      } else if (alphabetIndex > 0) {
        const prevInputRef = inputRefs[answerWord][alphabetIndex - 1]
        if (prevInputRef.current) {
          prevInputRef.current.focus()
        }
      }
    }


    return words.map((word, wordIndex) => {
      if (word === '#blank#') {
        const answerWord = answerArray[blankIndex]
        const answerWordWithIndex = answerArray[blankIndex] + wordIndex
        const alphabets = answerWord.split('')

        inputRefs[answerWordWithIndex] = []

        const inputFields = alphabets.map((alphabet, alphabetIndex) => {
          const inputRef = React.createRef()
          inputRefs[answerWordWithIndex][alphabetIndex] = inputRef

          return (
            <input
              key={alphabetIndex}
              type="text"
              className="input__text"
              value={alphabetIndex > 1 ? inputValues[answerWordWithIndex] ? inputValues[answerWordWithIndex][alphabetIndex] || '' : '' : alphabet}
              onChange={(e) => handleInputChange(answerWordWithIndex, alphabetIndex, e.target.value)}
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  handleBackspace(answerWordWithIndex, alphabetIndex)
                }
              }}
            />
          )
        })

        blankIndex++
        return (
          <span key={wordIndex}>
            {inputFields}
          </span>
        )
      } else {
        return <span key={wordIndex} style={{ lineHeight: '38px' }}>{word} </span>
      }
    })
  }

  return (
    <>
      <div className="row p-5">
        <div className="col-md-12 text-center">
          <h1>Type the missing letters to complete the paragraph below</h1>
        </div>
        {
          !showAnswer
            ? <>
              <div className="container">
                <div className="col-md-12 mt-5">
                  {renderQuestionWithInputs()}
                </div>
              </div>
            </>
            : <>
              <div className="row mt-5 mb-5">
                <div className="col-12">
                  <p>
                    {(() => {
                      let count = 0
                      const answerArray = taskInfo ? JSON.parse(taskInfo?.answer) : []
                      return (
                        <>
                          {taskInfo?.question.split(' ').map((item, index) => {
                            if (item === '#blank#') {
                              const answerWord = answerArray[count]
                              const answerWordWithIndex = answerArray[count] + index
                              const inputAwnser = inputValues[answerWordWithIndex]?.join("") || ""
                              const isCorrect = inputAwnser === answerWord.slice(2)
                              const className = isCorrect ? 'ans_green' : 'ans_red'
                              count += 1
                              return (
                                <span key={index} className={className}>
                                  {answerWord.substring(0, 2) + inputAwnser}
                                </span>
                              )
                            } else {
                              return (
                                <> {item}</>
                              )
                            }
                          })}
                        </>
                      )
                    })()}
                  </p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 grey__box">
                  <h2>Correct Answer</h2>
                  <p>
                    {(() => {
                      let count = 0
                      return (
                        <>
                          {taskInfo?.question.split(' ').map((item, index) => {
                            if (item === '#blank#') {
                              const answerValue = JSON.parse(taskInfo?.answer)[count]

                              count += 1

                              return (
                                <span key={index} className="ans_blue">
                                  {answerValue}
                                </span>
                              )
                            } else {
                              return (
                                <> {item}</>
                              )
                            }
                          })}
                        </>
                      )
                    })()}
                  </p>
                </div>
              </div>
            </>
        }
      </div>
      <SubmitRetryButtons retry={reset} submit={submit} />
    </>
  )
}

export default ReadAndComplete
