import React from 'react'
import TextEditorWithCounter from 'Components/TextEditorWithCounter'
import ShowAnswerComponent from 'Components/ShowAnswerComponent'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
import AudioPlayer from 'Components/AudioPlayer'
import RemainingListining from 'Components/RemainingListining'

function ListenAndType(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [inputTextData, setInputTextData] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const [countPlay, setCountPlay] = React.useState(3)
  const [playingAudio, setPlayingAudio] = React.useState(false)
  const API_URL = process.env.REACT_APP_DOCUMENT_URL_DOCUMENT_URL
  const audioRef = React.createRef()

  const playAudio = () => {
    if (countPlay <= 0) return
    audioRef.current.play()
    setCountPlay(countPlay - 1)
    setPlayingAudio(true)
  }

  const stopAudio = () => {
    audioRef.current.pause()
    audioRef.current.currentTime = 0
    setPlayingAudio(false)
  }

  const handleInput = (e) => {
    const { value } = e.target
    setInputTextData(value)
  }

  const reset = () => {
    setInputTextData("")
    setShowAnswer(false)
    setCountPlay(3)
    setPlayingAudio(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }
  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])


  const customProps = {
    taskInfo,
    handleInput,
    inputTextData
  }

  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Listen and type what you hear</h1>
      </div>

      <div className="container">


        {
          !showAnswer
            ? <>
              <audio id="myAudio" ref={audioRef} onEnded={stopAudio}>
                <source src={`${API_URL}/audio/${taskInfo?.audio}`} type="audio/mpeg" />
              </audio>
              <AudioPlayer playingAudio={playingAudio} playAudio={playAudio} stopAudio={stopAudio} />
              <RemainingListining countPlay={countPlay} />
              <TextEditorWithCounter {...customProps} />
            </>
            : <ShowAnswerComponent {...customProps} />
        }
        {
          <SubmitRetryButtons retry={reset} submit={submit} />
        }
      </div>
    </div>
  )
}

export default ListenAndType