
function Footer() {
    return (
        <>
            <footer id="footer">
                <div className="container py-4">
                    <div className="copyright">
                        &copy; Copyright <strong><span>ABC</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by <a href="#">ABC</a>
                    </div>
                </div>
            </footer>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
                className="bi bi-arrow-up-short"></i></a>
        </>
    )
}

export default Footer