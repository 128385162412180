import * as React from 'react'

function Header(props) {
    const { handleOpenModal } = props

    const [userInfo, setUserInfo] = React.useState(sessionStorage.getItem('name'))

    function login() {
        if (!userInfo) return false
        return true
    }

    const logout = () => {
        sessionStorage.clear()
        window.location.href = "/"
    }

    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center justify-content-between">

                <a href="/" className="logo">
                    <img src={`/assets/website/img/logo.jpg`} alt="logo" className="img-fluid" />
                </a>

                <nav id="navbar" className="navbar">
                    {
                        login()
                            ? <button className="btn-navbar" onClick={logout}>Logout</button>
                            : <span className="dropdown">
                                <button className="btn-navbar dropdown">Login</button>
                                <ul>
                                    <li>
                                        <a
                                            href="#"
                                            onClick={handleOpenModal}
                                        >Student
                                        </a>
                                    </li>
                                    <li><a href="admin">Admin</a></li>
                                    <li><a href="admin">SuperAdmin</a></li>
                                </ul>
                            </span>
                    }
                </nav>

            </div>
        </header>
    )
}

export default Header