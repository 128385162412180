import React from 'react'

function SelectTest({ tests, getAllTasksByIds }) {
    return (
        <div className="row p-5">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Test Name</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tests.length > 0
                            ? tests.map((test, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{test?.test?.test_name}</td>
                                    <td>
                                        <button
                                            onClick={getAllTasksByIds(test?.test?.test_array)}
                                            style={{ textTransform: 'none' }}
                                            className="btn btn-select-text py-1 px-3">
                                            Start  test
                                        </button>
                                    </td>
                                </tr>
                            ))
                            : <tr><td colSpan={3}>No test found..</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SelectTest