import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'

function AlertTost(props) {
    const position = props?.position || 'top-end'
    const bgColor = props?.type || 'success'

    return (
        <ToastContainer position={position} className="p-3" style={{ zIndex: 11111 }}>
            <Toast onClose={props?.onCloseTost} show={props?.open} delay={3000} autohide={true} bg={bgColor}>
                <Toast.Body className={'text-white'}>{props?.body}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default AlertTost