import * as React from 'react'
import SelectTest from './SelectTest'
import Select from 'react-select'
import AxiosInstance from 'Functions/AxiosInstance'
import * as AllTasks from "Components/Tasks"
import useCountDown from 'Hooks/useCountDown'

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'black',
        borderColor: 'black',
        fontSize: '13px',
        cursor: 'pointer',
        '&:hover': {
            borderColor: 'black',
        },
        boxShadow: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        minWidth: '180px',

    }),
    option: (provided, { isSelected }) => ({
        ...provided,
        backgroundColor: isSelected ? 'black' : 'white',
        color: isSelected ? 'white' : 'black',
        fontSize: '13px',
        width: '160px',
        cursor: 'pointer'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white',
        fontSize: '13px',
    }),
}

const taskOptions = [
    { value: "All Task", label: "All Task" },
    { value: "Listen And Type", label: "Listen And Type" },
    { value: "Listen And Select", label: "Listen And Select" },
    { value: "Listen Then Speak", label: "Listen Then Speak" },
    { value: "Read And Select", label: "Read And Select" },
    { value: "Read And Complete", label: "Read And Complete" },
    { value: "Read Aloud", label: "Read Aloud" },
    { value: "Read Then Write", label: "Read Then Write" },
    { value: "Read Then Speak", label: "Read Then Speak" },
    { value: "Speak About The Photo", label: "Speak About The Photo" },
    { value: "Write About The Photo", label: "Write About The Photo" }
]
const difficultyOptions = [
    { value: "All Type", label: "All Type" },
    { value: "Easy", label: "Easy" },
    { value: "Medium", label: "Medium" },
    { value: "Hard", label: "Hard" }
]

function Dashboard() {
    const [studentId, setStudentId] = React.useState(sessionStorage.getItem('student_id'))
    const [userInfo, setUserInfo] = React.useState(sessionStorage.getItem('name'))
    const [isTestMode, setIsTestMode] = React.useState(false)
    const [taskTypeIndex, setTaskTypeIndex] = React.useState(0)
    const [taskDiffIndex, setTaskDiffIndex] = React.useState(0)
    const [openedTaskKey, setOpenedTaskKey] = React.useState(0)
    const [currentOpenedTask, setCurrentOpenedTask] = React.useState(null)
    const [tests, setTests] = React.useState([])
    const [userTasks, setUserTasks] = React.useState([])
    const [countDown, setCountDown] = React.useState(null)
    const { countDownTime, startCountDown, resetCountDown, countDownFinished } = useCountDown(countDown)

    const removeSpaces = (str) => {
        return str?.replace(/\s+/g, '')
    }

    const handleTaskType = (e) => {
        const { value } = e
        const index = taskOptions.findIndex((task) => task.value === value)
        setTaskTypeIndex(index)
    }

    const handleTaskDiff = (e) => {
        const { value } = e
        const index = difficultyOptions.findIndex((task) => task.value === value)
        setTaskDiffIndex(index)
    }

    const handleNextTask = () => {
        resetCountDown()
        // setCurrentOpenedTask("")
        if (openedTaskKey >= userTasks.length - 1) return
        setOpenedTaskKey(prev => (prev + 1))
    }

    const handlePreviousTask = () => {
        resetCountDown()
        // setCurrentOpenedTask("")
        if (openedTaskKey > 0) setOpenedTaskKey(prev => (prev - 1))
    }

    const resetCountDownTimer = async () => {
        resetCountDown()
        await new Promise(resolve => setTimeout(resolve, 50))
        startCountDown()
    }

    React.useEffect(() => {
        if (!userTasks) return
        if (userTasks.length === 0) return
        setCountDown(null)
        const task = userTasks[openedTaskKey]
        const taskType = removeSpaces(task.task_type)
        setCurrentOpenedTask(!taskType ? 'Error' : taskType)
        if (task?.task_timer != "") setCountDown(parseInt(task?.task_timer))
    }, [openedTaskKey, userTasks])

    React.useEffect(() => {
        startCountDown()
    }, [currentOpenedTask])

    React.useMemo(() => {
        if (taskDiffIndex == 0 && taskTypeIndex == 0) return
        async function getTaskListAccordingtoType() {
            const body = {}
            if (taskDiffIndex != 0) body.task_diff = difficultyOptions?.find((task, index) => index == taskDiffIndex)?.value
            if (taskTypeIndex != 0) body.task_type = taskOptions?.find((task, index) => index == taskTypeIndex)?.value

            const config = {
                url: `/api/tests-by-types`,
                method: "POST",
                data: body
            }

            const response = await AxiosInstance(config)
            if (response) {
                const { tests, status } = response.data
                if (status) setUserTasks(tests)
            }
        }
        getTaskListAccordingtoType()
    }, [taskDiffIndex, taskTypeIndex])

    React.useMemo(() => {
        async function getStudentTestList() {
            const id = studentId

            const config = {
                url: `/api/tests/${id}`,
                method: "GET",
            }

            const response = await AxiosInstance(config)
            if (response) {
                const { tests, status } = response.data
                if (status) setTests(tests)
            }
        }
        getStudentTestList()
    }, [])


    const getAllTasksByIds = (array) => async () => {
        const body = { 'task_ids': array }

        const config = {
            url: `/api/tasks`,
            method: "POST",
            data: body
        }

        const response = await AxiosInstance(config)
        if (response) {
            const { tasks, status } = response.data
            if (status) {
                setUserTasks(tasks)
                setIsTestMode(true)
            }
        }
    }

    const tasksProps = {
        openedTaskKey,
        currentOpenedTask,
        userTasks,
        countDownFinished,
        resetCountDownTimer
    }

    return (
        <div className="container" style={{ minHeight: '91.5vh' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header py-3">
                            <div className="row min-height-header">
                                <div className="col-md-4 d-flex align-items-center justify-content-around">
                                    <div className="user-img d-flex justify-content-center align-items-center">
                                        <img
                                            src="https://www.pinclipart.com/picdir/big/116-1168985_male-icon-1stacy-dreher2017-08-01t16-business-clipart.png"
                                            alt=""
                                            className="user-icon"
                                        />
                                    </div>
                                    <p className="pl-5 mb-0">&nbsp;&nbsp;&nbsp;Welcome, {userInfo}</p>
                                </div>
                                <div className="offset-md-2 col-md-6 offset-xl-4 col-xl-4 d-flex justify-content-around align-items-center">

                                    <div style={{ minWidth: '180px' }}>
                                        {!isTestMode &&
                                            <Select
                                                closeMenuOnSelect={true}
                                                defaultValue={taskOptions[taskTypeIndex]}
                                                options={taskOptions}
                                                size={'small'}
                                                styles={customStyles}
                                                onChange={handleTaskType}
                                            />
                                        }
                                    </div>
                                    <div style={{ minWidth: '120px' }}>
                                        {!isTestMode &&
                                            <Select
                                                closeMenuOnSelect={true}
                                                defaultValue={difficultyOptions[taskDiffIndex]}
                                                options={difficultyOptions}
                                                size={'small'}
                                                styles={customStyles}
                                                onChange={handleTaskDiff}
                                            />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            {
                                countDown !== null &&
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end align-items-center">
                                        <div className="timer">
                                            <h2 id="timer">{countDownTime}</h2>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                currentOpenedTask && AllTasks[currentOpenedTask]
                                    ? (React.createElement(AllTasks[currentOpenedTask], { ...tasksProps }))
                                    : <SelectTest tests={tests} getAllTasksByIds={getAllTasksByIds} />
                            }



                            <hr />
                            <div className='container'>

                                <div className={`row ${openedTaskKey > 0 ? 'justify-content-between' : 'justify-content-end'}`}>
                                    {
                                        openedTaskKey > 0 &&
                                        <button onClick={handlePreviousTask} className="btn btn-primary btn__blue mt-5">Previous Task</button>
                                    }

                                    {/* <button onClick={handleNextTask} className="btn btn-primary btn__blue mt-5">Next</button> */}
                                    {
                                        openedTaskKey < userTasks?.length - 1 &&
                                        <button onClick={handleNextTask} className="btn btn-primary btn__blue mt-5">Next Task</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >

    )
}

export default Dashboard