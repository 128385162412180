import React from 'react'

function RemainingListining({ countPlay }) {
    return (
        <div className="row justify-content-center mt-4">
            <p className="text-black text-center"><span>{countPlay}</span> Listens Remaining</p>
        </div>
    )
}

export default RemainingListining