import React from 'react'
import MicophoneButton from 'Components/MicophoneButton'
import { useTimer } from 'Hooks'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
import ShowAnswerComponent from 'Components/ShowAnswerComponent'
let recognition = null

function ReadAloud(props) {
  const [isSupportSpeechAPI, setIsSupportSpeechAPI] = React.useState(true)
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [speakText, setSpeakTest] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const { timer, startTimer, resetTimer, stopTimer } = useTimer()



  const startListening = () => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      setIsSupportSpeechAPI(null)
      return
    }
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    recognition.continuous = true
    recognition.interimResults = true

    let newTranscript = ""
    recognition.onresult = event => {
      newTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('')
      setSpeakTest(newTranscript)
    }

    recognition.onend = () => {
      setSpeakTest(newTranscript)
    }
    recognition.start()
    startTimer()
  }

  const reset = () => {
    setSpeakTest("")
    resetTimer()
    setShowAnswer(false)
    resetCountDownTimer()

  }

  const submit = () => {
    setShowAnswer(true)
    recognition?.stop()
    stopTimer()
  }

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])


  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Record yourself reading the sentence below</h1>
      </div>


      <div className="container">
        {
          !showAnswer
            ?
            <>
              <div className="col-md-8 offset-md-2">
                <div className="row mt-5 pl-3">
                  <p>
                    {taskInfo?.question}
                  </p>
                </div>
              </div>
              <MicophoneButton isSupportSpeechAPI={isSupportSpeechAPI} startListening={startListening} timer={timer} />
            </>
            : <ShowAnswerComponent inputTextData={speakText} taskInfo={taskInfo} />
        }
      </div>
      {
        isSupportSpeechAPI && <SubmitRetryButtons retry={reset} submit={submit} />
      }
    </div>
  )
}

export default ReadAloud