import { useState, useEffect } from 'react'

function useTimer() {
    const [timer, setTimer] = useState("00:00")
    const [isActive, setIsActive] = useState(false)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        let interval = null
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1)
                updateTimerString(seconds + 1)
            }, 1000)
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [isActive, seconds])

    const startTimer = () => {
        setIsActive(true)
    }
    const stopTimer = () => {
        setIsActive(false)
    }

    const resetTimer = () => {
        setIsActive(false)
        setSeconds(0)
        updateTimerString(0)
    }

    const updateTimerString = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        const minutesString = String(minutes).padStart(2, '0')
        const secondsString = String(remainingSeconds).padStart(2, '0')
        setTimer(`${minutesString}:${secondsString}`)
    }

    return { timer, startTimer, resetTimer, stopTimer }
}

export default useTimer
