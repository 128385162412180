import { Route, Routes, useLocation } from 'react-router-dom'
import { Dashboard, Home } from './Pages'
import * as React from 'react'
import { Footer, Header } from './Components'
import ProtectedRoutes from './ProtectedRoute'


const userInfo = sessionStorage.getItem('student_id')

function login() {
  if (!userInfo) return false
  return true
}

function App() {
  const [openModal, setOpenModal] = React.useState(false)
  const location = useLocation()
  console.log('location:', location.pathname)


  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const loginModalProps = {
    openModal, setOpenModal, handleOpenModal, handleCloseModal
  }
  return (
    <>
      <Header {...loginModalProps} />
      <Routes>
        <Route path="/" exact element={<Home {...loginModalProps} />} />
        {/* <Route path="*" exact element={<Home {...loginModalProps} />} /> */}
        <Route path="/" element={<ProtectedRoutes login={login()} />}>
          <Route path="/dashboard" exact element={<Dashboard />} />
        </Route>
      </Routes>
      <Footer />
    </>
  )
}

export default App
