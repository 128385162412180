import * as React from 'react'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'

const boxCss = {
  padding: "0.4rem 1rem",
  borderRadius: "0 25px 25px 0"
}

function ListenAndSelect(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [selectedAnswers, setSelectedAnswers] = React.useState([])
  const [audioRefs, setAudioRefs] = React.useState([])
  const [taskInfo, setTaskInfo] = React.useState({})
  const [audios, setAudios] = React.useState([])
  const [showAnswer, setShowAnswer] = React.useState(false)
  const API_URL = process.env.REACT_APP_DOCUMENT_URL_DOCUMENT_URL

  const handleAudioClick = (audioName) => () => {
    let currentAudios = [...selectedAnswers]
    if (currentAudios.includes(audioName)) {
      currentAudios = currentAudios.filter((audio) => audio !== audioName)
    } else {
      currentAudios.push(audioName)
    }
    setSelectedAnswers(currentAudios)
  };


  const getClassNameBySelectedWords = (object) => {
    const { status, audio } = object
    const isSelected = selectedAnswers.includes(audio)

    if (isSelected) {
      return status === "True" ? "bc-green" : "bc-red"
    } else {
      return status === "True" ? "bo-green" : "bo-red"
    }
  }


  const playAudio = (audioRef) => () => {
    if (!audioRef) return
    audioRef.play()
  }

  const reset = () => {
    setSelectedAnswers([])
    setShowAnswer(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }
  React.useMemo(() => {
    const task = userTasks[openedTaskKey]
    if (!task) return
    setTaskInfo(task)
    if (!task?.audio.startsWith("[{")) return
    setAudios(JSON.parse(task?.audio))
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  return (
    <div className="row mt-5">
      <div className="col-md-12 text-center">
        <h1>Listen and select the real English Words</h1>
      </div>
      <div className="container">
        <div className="col-md-8 mt-5 mx-auto">
          {
            !showAnswer && (
              <div className="row pl-5 pr-5">
                {
                  audios?.map((task, index) => {
                    const isAudioClicked = selectedAnswers.includes(task?.audio)

                    return (
                      <div className="col-md-4" key={index}>
                        <audio ref={(audio) => (audioRefs[index] = audio)}>
                          <source src={`${API_URL}/audio/${task.audio}`} type="audio/mpeg" />
                        </audio>
                        <div
                          className={`box mb-5 d-flex align-items-center justify-content-between ${isAudioClicked && "clicked"}`}
                          style={boxCss}
                        >
                          <div className="audio-box" onClick={playAudio(audioRefs[index])}>
                            <img src={`/assets/website/img/volume.png`} alt="" />
                          </div>
                          <div className="ml-3">
                            <div className="label_text">
                              <span className="mb-0">Word {index + 1}</span>
                            </div>
                          </div>
                          <div className="check-box" onClick={handleAudioClick(task.audio)}>
                            <img src={`/assets/website/img/check-mark.png`} alt="" />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }


          {
            showAnswer && (
              <div className="row pl-5 pr-5">
                {audios?.map((task, index) => (
                  <div className="col-md-4" key={index}>
                    <div
                      className={`box mb-5 ${getClassNameBySelectedWords(task)}`}
                      style={{ ...boxCss, padding: "0.65rem 1rem" }}
                    >
                      Word {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            )
          }

          {
            <SubmitRetryButtons retry={reset} submit={submit} />
          }
        </div>
      </div>
    </div>
  )
}

export default ListenAndSelect