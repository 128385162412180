import React from 'react'

function SubmitRetryButtons({ retry, submit }) {
    return (
        <div className='container'>
            <div className="row justify-content-around">
                <button className="btn btn-primary btn__blue mt-5" onClick={retry}>Retry</button>
                <button className="btn btn-primary btn__blue mt-5" onClick={submit}>Submit</button>
            </div>
        </div>
    )
}

export default SubmitRetryButtons