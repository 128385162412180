import React from 'react'

const AudioPlayer = ({ playingAudio, playAudio, stopAudio }) => (
    <div className="row justify-content-center mt-5">
        <div className="play d-flex justify-content-center align-items-center audio__play__btns">
            {
                !playingAudio
                    ? <img src={`/assets/website/img/play.png`} onClick={playAudio} />
                    : <img src={`/assets/website/img/pause.png`} onClick={stopAudio} />
            }
        </div>
    </div>
)

export default AudioPlayer