import React from 'react'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
import ShowAnswerComponent from 'Components/ShowAnswerComponent'
import TextEditorWithCounter from 'Components/TextEditorWithCounter'

function ReadThenWrite(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [inputTextData, setInputTextData] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)

  const handleInput = (e) => {
    const { value } = e.target
    setInputTextData(value)
  }

  const reset = () => {
    setInputTextData("")
    setShowAnswer(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }
  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])


  const customProps = {
    taskInfo,
    handleInput,
    inputTextData
  }
  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Write about the question in at least 50 words</h1>
      </div>

      <div className="container">
        {
          !showAnswer
            ?
            <>
              <Question {...customProps} />
              <TextEditorWithCounter {...customProps} />
            </>
            : <ShowAnswerComponent {...customProps} />
        }
        {
          <SubmitRetryButtons retry={reset} submit={submit} />
        }
      </div>
    </div>
  )
}

export default ReadThenWrite


const Question = ({ taskInfo }) => (
  <div className="col-md-8 offset-md-2">
    <div className="row mt-5 pl-3">
      <p>
        {taskInfo?.question}
      </p>
    </div>
  </div>
)


