import React from 'react'
import { useTimer } from 'Hooks'
import SubmitRetryButtons from 'Components/SubmitRetryButtons'
let recognition = true

function SpeakAboutThePhoto(props) {
  const { openedTaskKey, countDownFinished, userTasks, resetCountDownTimer } = props
  const [isSupportSpeechAPI, setIsSupportSpeechAPI] = React.useState(true)
  const [speakText, setSpeakTest] = React.useState("")
  const [taskInfo, setTaskInfo] = React.useState(null)
  const [showAnswer, setShowAnswer] = React.useState(false)
  const { timer, startTimer, resetTimer } = useTimer()
  const API_URL = process.env.REACT_APP_DOCUMENT_URL_DOCUMENT_URL


  const startListening = () => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      setIsSupportSpeechAPI(null)
      return
    }
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    recognition.continuous = true
    recognition.interimResults = true

    let newTranscript = ""
    recognition.onresult = event => {
      newTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('')
      setSpeakTest(newTranscript)
    }

    recognition.onend = () => {
      setSpeakTest(newTranscript)
    }
    recognition.start()
    startTimer()
  }

  const reset = () => {
    setSpeakTest("")
    resetTimer()
    setShowAnswer(false)
    resetCountDownTimer()
  }

  const submit = () => {
    setShowAnswer(true)
  }

  React.useMemo(() => {
    setTaskInfo(userTasks[openedTaskKey])
    reset()
  }, [openedTaskKey])

  React.useMemo(() => {
    if (!countDownFinished) return
    submit()
  }, [countDownFinished])

  return (
    <div className="row p-5">
      <div className="col-md-12 text-center">
        <h1>Describe the photo aloud</h1>
      </div>

      <div className="container">

        <div className="col-12 col-md-6 mx-auto">
          <div className="row mt-5 pl-3">
            <img className="question__img" src={`${API_URL}/images/${taskInfo?.image}`} />
          </div>
        </div>

        {
          !showAnswer
            ? <div className="row mt-4">
              <div className="col-md-6 offset-md-3">

                <div className="speak__box d-flex justify-content-between align-items-center flex-column py-4">
                  {
                    !isSupportSpeechAPI
                      ? <p className="no-browser-support text-danger error-label">Sorry, Your Browser Doesn't Support the Web Speech API. Try Opening this in Google Chrome.</p>
                      : <>
                        <div className="microphone" onClick={startListening}>
                          <img src={`/assets/website/img/microphone.png`} id="startBtn" />
                        </div>
                        <div className="speak__timer">
                          <h2 style={{ fontSize: '2.6rem' }} id="speak_timer">{timer}</h2>
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>

            : <div className="col-md-8 mt-5 offset-md-2 grey__box">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <h2 className="pl-1">Your Answer</h2>
                  </div>
                  <p>{speakText}</p>
                </div>
              </div>
              <hr />
              <div className="row mt-5">
                <div className="col-12">
                  <h2>Correct Answer</h2>
                  <p>{taskInfo?.answer}</p>
                </div>
              </div>
            </div>
        }
        {isSupportSpeechAPI && <SubmitRetryButtons retry={reset} submit={submit} />}
      </div>
    </div>
  )
}

export default SpeakAboutThePhoto