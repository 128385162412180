import { Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap'
import AxiosInstance from 'Functions/AxiosInstance'
import * as React from 'react'
import AlertTost from 'Components/AlertTost'
import { useNavigate } from 'react-router-dom'


const loginObject = {
    "username": "",
    "password": ""
}
const loginErrorObject = {
    "username": "",
    "password": ""
}

function LoginComponent(props) {
    const { openModal, setOpenModal, handleCloseModal } = props
    const navigate = useNavigate()
    const [formData, setFormData] = React.useState(loginObject)
    const [formDataErrors, setFormDataErrors] = React.useState(loginErrorObject)


    const [tostType, setTostType] = React.useState("success")
    const [tostBody, setTostBody] = React.useState("")
    const [tostOpen, setTostOpen] = React.useState(false)

    const onCloseTost = () => {
        setTostOpen(false)
    }

    React.useMemo(() => {
        if (openModal) return
        setFormData(loginObject)
        setFormDataErrors(loginErrorObject)
    }, [openModal])

    const createError = (name, value) => {
        const error = value === "" ? true : false
        setFormDataErrors(prev => ({ ...prev, [name]: error }))
    }
    const handleUsername = (e) => {
        const { value } = e.target
        setFormData(prevState => ({ ...prevState, "username": value }))
        createError("username", value)
    }
    const handlePassword = (e) => {
        const { value } = e.target
        setFormData(prevState => ({ ...prevState, "password": value }))
        createError("password", value)
    }

    const LoginSubmit = async () => {
        const { username, password } = formData
        createError("username", username)
        createError("password", password)
        if (username == "" || password == "") return
        const body = { "username": username, "password": password }

        const config = {
            url: "/api/login",
            method: 'POST',
            data: body,
        }

        const response = await AxiosInstance(config)
        if (response) {
            const { message, IsStudent, WebStudent, status } = response.data
            if (status) {
                for (const key in WebStudent) {
                    sessionStorage.setItem(key, WebStudent[key]);
                }
                sessionStorage.setItem('IsStudent', IsStudent)
                setTostType('success')
                setTostBody(message)
                setTostOpen(true)
                setOpenModal(false)
                await new Promise((resolve) => setTimeout(resolve, 1500))
                window.location.href = "/dashboard"
            } else {
                setTostType('danger')
                setTostBody(message)
                setTostOpen(true)
            }
        }
    }


    return (
        <>
            <AlertTost
                type={tostType}
                body={tostBody}
                open={tostOpen}
                onCloseTost={onCloseTost}
            />
            <Modal show={openModal} centered onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Student Login</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="email" value={formData?.username} onChange={handleUsername} />
                            <span className='text-danger error-label'>
                                {formDataErrors?.username && "Please enter your username"}
                            </span>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' value={formData?.password} onChange={handlePassword} />
                            <span className='text-danger error-label'>
                                {formDataErrors?.password && "Please enter your password"}
                            </span>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={LoginSubmit}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default LoginComponent